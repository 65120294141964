import axios from "axios";

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "",
  // 超时
  timeout: 5000
});

// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  res => {
    // console.log("成功");
    // console.log(res);

    return Promise.resolve(res.data);
  },
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (window.navigator.onLine) {
        return Promise.reject(error);
      }
    }
  }
);

export default service;
