import request from "@/utils/request2";

// 用户登录
export function StandardApiAction_login(query) {
  return request({
    url: "http://117.34.72.245:8088/StandardApiAction_login.action",
    method: "GET",
    params: query
  });
}

// 车辆信息
export function StandardApiAction_queryUserVehicle(query) {
  return request({
    url: "http://117.34.72.245:8088/StandardApiAction_queryUserVehicle.action",
    method: "GET",
    params: query
  });
}

// 获取设备号
export function StandardApiAction_getDeviceByVehicle(query) {
  return request({
    url:
      "http://117.34.72.245:8088/StandardApiAction_getDeviceByVehicle.action",
    method: "GET",
    params: query
  });
}

// 设备在线
export function StandardApiAction_getDeviceOlStatus(query) {
  return request({
    url: "http://117.34.72.245:8088/StandardApiAction_getDeviceOlStatus.action",
    method: "GET",
    params: query
  });
}

// 获取gps
export function StandardApiAction_getDeviceStatus(query) {
  return request({
    url: "http://117.34.72.245:8088/StandardApiAction_getDeviceStatus.action",
    method: "GET",
    params: query
  });
}

// 获取轨迹
export function StandardApiAction_queryTrackDetail(query) {
  return request({
    url: "http://117.34.72.245:8088/StandardApiAction_queryTrackDetail.action",
    method: "GET",
    params: query
  });
}

// 视频
export function getvide(query) {
  return request({
    url: "http://117.34.72.245:6604/3/3",
    method: "GET",
    params: query
  });
}
