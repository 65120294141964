<template>
  <div class="container">
    <div class="flex-box">
      <div class="box-left" :style="{ width: width }">
        <el-tree
          :data="treeData"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          @check="check"
        >
        </el-tree>
      </div>
      <div class="box-right">
        <div class="map-container" id="amap"></div>
      </div>
    </div>
    <el-dialog title="查询" :visible.sync="dialogFormVisible">
      <el-form :model="ruleform" ref="ruleform">
        <el-form-item
          label="查询日期"
          prop="time"
          :rules="[{ required: true, message: '时间不能为空' }]"
        >
          <el-date-picker
            v-model="ruleform.time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="seach">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  StandardApiAction_login,
  StandardApiAction_queryUserVehicle,
  StandardApiAction_getDeviceByVehicle,
  StandardApiAction_getDeviceOlStatus,
  StandardApiAction_getDeviceStatus,
  StandardApiAction_queryTrackDetail
} from "@/api/tree_map";
var map;
var infoWindow;
var marker2;
export default {
  data() {
    return {
      dialogFormVisible: false,
      ruleform: {
        time: ""
      },

      width: "260px",
      jsession: "",
      vehiIdno: [],
      treeData: [
        {
          id: 1,
          label: "监控中心",
          children: []
        }
      ],

      gpsCarEqu: [],
      addMarker: [],
      shebeiId: ""
    };
  },
  mounted() {
    this.getMap();
    this.StandardApiAction_login();
  },
  methods: {
    seach() {
      this.$refs["ruleform"].validate(valid => {
        if (valid) {
          this.dialogFormVisible = false;
          // console.log(this.ruleform.time);
          // console.log(this.shebeiId);
          this.StandardApiAction_queryTrackDetail(
            this.shebeiId,
            this.ruleform.time
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    check() {
      this.StandardApiAction_getDeviceStatus();
    },
    handleNodeClick(data) {
      if (marker2) {
        marker2.stopMove();
      }

      map.clearMap(); // 清除地图覆盖物
      this.addOverlay();
      let res = this.gpsCarEqu.find(el => el.vid == data.label);
      if (res) {
        let { mlng, mlat } = res || {};
        map.setCenter([mlng, mlat]); //设置地图中心点
        this.openInfo([mlng, mlat], res);
      } else {
        infoWindow.close();
        map.setFitView(); //地图自适应
      }
    },
    getMap() {
      map = new window.AMap.Map("amap", {
        zoom: 11, //级别
        center: [116.397428, 39.90923], //中心点坐标
        viewMode: "3D", //使用3D视图
        resizeEnable: true
      });
    },

    // 点标记
    addOverlay() {
      map.clearMap(); // 清除地图覆盖物

      // 创建一个 icon
      let endIcon = new window.AMap.Icon({
        size: new window.AMap.Size(17, 39),
        image: require("@/assets/car.png"),
        imageSize: new window.AMap.Size(17, 39)
      });

      let endIcon2 = new window.AMap.Icon({
        size: new window.AMap.Size(17, 39),
        image: require("@/assets/car2.png"),
        imageSize: new window.AMap.Size(17, 39)
      });

      this.addMarker = [];
      this.gpsCarEqu.forEach(el => {
        let marker = new window.AMap.Marker({
          position: new window.AMap.LngLat(el.mlng, el.mlat),
          icon: el.ol == 1 ? endIcon : endIcon2
        });
        marker.content = el;
        // console.log(el);
        marker.on("click", this.markerClick);
        // marker.emit("click", { target: marker });
        this.addMarker.push(marker);
      });

      // 将 markers 添加到地图
      map.add(this.addMarker);
      map.setFitView(); //地图自适应
    },
    markerClick(e) {
      this.openInfo(e.target.getPosition(), e.target.content);
      map.setFitView(); //地图自适应
    },
    openInfo(position, content) {
      //构建信息窗体中显示的内容
      // var info = [];
      // info.push(
      //   "<div style='color:#000;padding:20px'><div>车牌号</div> <span>在线</span>"
      // );
      // info.push("<div>时间</div>");
      // info.push("<div>收运单位</div>");
      // info.push("<div>驾驶人员</div>");
      // info.push("<div>联系方式: 010-84107000</div>");
      // info.push(
      //   "<div>当前位置:北京市朝阳区望京阜荣街10号首开广场4层</div></div>"
      // );

      let infoWindowContent =
        `<div class="custom-infowindow input-card" style="color:#000">` +
        `<label style="color:grey">${content.vid}</label><span>${
          content.ol == 1 ? "在线" : "离线"
        }</span>` +
        `<div>时间：${content.gt}</div><div>速度：${content.sp / 10}km/h ${
          content.hx
        }</div>` +
        `<div>时间：${content.gt}</div>` +
        `<div>收运单位：</div>` +
        `<div>驾驶人员：${content.dn || ""}</div>` +
        `<div>当前地址：${content.ps || ""}</div>` +
        // 为 infowindow 添加自定义事件
        `<button type="primary"  onclick="getLngLat(${content.id})">查看轨迹</button>` +
        "</div>";

      infoWindow = new window.AMap.InfoWindow({
        offset: new window.AMap.Pixel(0, 0),
        content: infoWindowContent
        // content: info.join("") //使用默认信息窗体框样式，显示信息内容
      });

      infoWindow.open(map, position);
      window.getLngLat = id => {
        this.dialogFormVisible = true;
        this.shebeiId = id;
      };
    },
    getLngLat() {
      alert("12345");
    },
    // 登录
    async StandardApiAction_login() {
      let data = {
        account: "cqhj",
        password: "000000"
      };
      let res = await StandardApiAction_login(data);
      this.jsession = res.jsession;
      this.StandardApiAction_queryUserVehicle();
    },
    // 获取车辆信息
    async StandardApiAction_queryUserVehicle() {
      let data = {
        jsession: this.jsession
      };
      let res = await StandardApiAction_queryUserVehicle(data);

      this.vehiIdno = [];
      res.vehicles.forEach(el => {
        this.vehiIdno.push(el.nm);
      });

      this.vehiIdno = this.vehiIdno.toString();
      this.StandardApiAction_getDeviceByVehicle();
    },
    // 获取设备号
    async StandardApiAction_getDeviceByVehicle() {
      let data = {
        jsession: this.jsession,
        vehiIdno: this.vehiIdno
      };

      let res = await StandardApiAction_getDeviceByVehicle(data);

      this.devIdno = [];
      res.devices.forEach(el => {
        this.devIdno.push(el.did);
        this.treeData[0].children.push({
          id: el.did,
          label: el.vid,
          children: []
        });
      });
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.treeData);
        this.StandardApiAction_getDeviceStatus();
      });
      // this.devIdno = this.devIdno.toString();
    },
    // 设备在线
    async StandardApiAction_getDeviceOlStatus(devIdno) {
      let data = {
        devIdno: devIdno
      };
      let res = await StandardApiAction_getDeviceOlStatus(data);
      console.log(res);
    },
    // 获取gps
    async StandardApiAction_getDeviceStatus() {
      // console.log(this.devIdno);
      let checked = this.$refs.tree.getCheckedKeys();
      console.log(this.jsession);
      if (checked.length != 0) {
        let data = {
          jsession: this.jsession,
          devIdno: checked.toString(),
          toMap: 1,
          driver: 1
        };

        let res = await StandardApiAction_getDeviceStatus(data);
        this.gpsCarEqu = res.status;
        console.log(this.gpsCarEqu);
        map.remove(this.addMarker);
        this.addOverlay();
      } else {
        map.remove(this.addMarker);
        map.setFitView();
      }
    },
    // 获取轨迹
    async StandardApiAction_queryTrackDetail(devIdno, time) {
      let data = {
        jsession: this.jsession,
        devIdno: devIdno,
        begintime: time + " 00:00:00",
        endtime: time + " 23:59:59",
        toMap: 1
      };
      let res = await StandardApiAction_queryTrackDetail(data);
      console.log(res);
      if (res.tracks != null) {
        map.clearMap();
        let tracks2 = [];
        res.tracks.forEach(el => {
          if (el.mlng != 0) {
            tracks2.push([Number(el.mlng), Number(el.mlat)]);
          }
        });
        if (tracks2.length != 0) {
          this.donghua(tracks2);
        } else {
          this.$message({
            message: "抱歉，当天没有历史轨迹",
            type: "warning"
          });
        }
      } else {
        this.$message({
          message: "抱歉，当天没有历史轨迹",
          type: "warning"
        });
      }
    },
    donghua(tracks) {
      // var path = []
      // const res = await StandardApiAction_queryTrackDetail(paramas)
      // console.log(res)

      // console.log(tracks);
      window.AMap.plugin("AMap.MoveAnimation", function() {
        // tracks = [
        //   [116.478935, 39.997761],
        //   [116.478939, 39.997825],
        //   [116.478912, 39.998549],
        //   [116.478912, 39.998549],
        //   [116.478998, 39.998555],
        //   [116.478998, 39.998555],
        //   [116.479282, 39.99856],
        //   [116.479658, 39.998528],
        //   [116.480151, 39.998453],
        //   [116.480784, 39.998302],
        //   [116.480784, 39.998302],
        //   [116.481149, 39.998184],
        //   [116.481573, 39.997997],
        //   [116.481863, 39.997846],
        //   [116.482072, 39.997718],
        //   [116.482362, 39.997718],
        //   [116.483633, 39.998935],
        //   [116.48367, 39.998968],
        //   [116.484648, 39.999861]
        // ];
        console.log(tracks);

        marker2 = new window.AMap.Marker({
          map: map,
          position: tracks[0],
          icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
          offset: new window.AMap.Pixel(-13, -26)
        });

        // 绘制轨迹
        new window.AMap.Polyline({
          map: map,
          path: tracks,
          showDir: true,
          strokeColor: "#28F", //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6 //线宽
          // strokeStyle: "solid"  //线样式
        });

        var passedPolyline = new window.AMap.Polyline({
          map: map,
          strokeColor: "#AF5", //线颜色
          strokeWeight: 6 //线宽
        });

        marker2.on("moving", function(e) {
          passedPolyline.setPath(e.passedPath);
          map.setCenter(e.target.getPosition(), true);
        });

        map.setFitView();
        setTimeout(() => {
          marker2.moveAlong(tracks, {
            // 每一段的时长
            duration: 500, //可根据实际采集时间间隔设置
            // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
            autoRotation: true
          });
        }, 3000);
        // window.startAnimation = function startAnimation() {

        // };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  height: 100vh;
  padding-top: 120px;
  .box-left {
    height: 100%;
    padding: 10px 10px;
    background: #fff;
    overflow-y: auto;
    transition: width 0.28s;
    position: relative;
  }
  .box-right {
    width: 100%;
    height: 100%;
  }
}

.map-container {
  width: 100%;
  height: 100%;
}
</style>
